import { CarouselTile as CarouselTileModel } from '@model/content';
import { TestId } from '@components/test-ids';
import { Carousel } from './Carousel';
import { CarouselTile } from './CarouselTile';

interface DynamicCarouselProps {
  title?: string;
  tiles: Array<CarouselTileModel>;
  testId?: string;
  renderTiles?: (tiles: Array<CarouselTileModel>) => Array<JSX.Element>;
}

export const FixedCarousel = ({ title, testId, tiles, renderTiles }: DynamicCarouselProps) => {
  if (!tiles) {
    return null;
  }

  const renderAllTiles = renderTiles
    ? renderTiles
    : (tiles: Array<CarouselTileModel>) => {
        return (tiles || []).map((tile) => <CarouselTile key={tile.heading} {...tile} />);
      };

  return (
    <Carousel testId={testId || TestId.dynamicCarousel.main} title={title}>
      {renderAllTiles(tiles)}
    </Carousel>
  );
};
