import uri from 'urijs';
import { EndPoint, getURL, Request } from '@model/service';
import { FooterModel } from '@model/content';
import { EnvConfig } from '@model/config/env-config';

interface FooterApiRequests {
  getMainFooter: () => Promise<FooterModel>;
  getCheckoutFooter: () => Promise<FooterModel>;
}

export class FooterApi implements FooterApiRequests {
  private api: Request = new Request();

  public async getMainFooter(): Promise<FooterModel> {
    const appVariantId = EnvConfig.get().APP_VARIANT_ID;
    return await this.api.get(
      uri(getURL()).path(EndPoint.FOOTER.concat(EndPoint.MAIN).concat('/').concat(appVariantId)).href()
    );
  }

  public async getCheckoutFooter(): Promise<FooterModel> {
    const appVariantId = EnvConfig.get().APP_VARIANT_ID;
    return await this.api.get(
      uri(getURL()).path(EndPoint.FOOTER.concat(EndPoint.CHECKOUT).concat('/').concat(appVariantId)).href()
    );
  }
}
