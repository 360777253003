import { BaseList } from '../base/BaseList';
import React from 'react';
import styled from '@emotion/styled';
import { TestId } from '@components/test-ids';
import { uniq } from 'lodash';
import { ThemeProps } from '@theme/base';

export interface BulletPointListProps {
  title?: string;
  items: Array<string>;
  renderItem?: (item: string) => JSX.Element;
}

export const ListItem = styled.li(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.paragraph as any),
  padding: theme.custom.spacing.xSmall,
  marginLeft: theme.custom.spacing.medium,
  listStyleImage: 'url(/assets/images/bullet_point.svg)'
}));

export const BulletPointList = ({ items, title, renderItem }: BulletPointListProps) => {
  return (
    <BaseList
      title={title}
      items={uniq(items)}
      renderItem={
        renderItem
          ? renderItem
          : (item: string) => (
              <ListItem data-testid={TestId.list.listItem} key={item}>
                {item}
              </ListItem>
            )
      }
    />
  );
};
