import styled from '@emotion/styled';
import { Localisation } from '@model/common';
import { withTheme } from '@emotion/react';
import React from 'react';
import { TestId } from '@components/test-ids';
import { Theme, ThemeProps } from '@theme/base';
import { useI18NextContext } from '@components/hooks';

/* ***************** *
 *       Types       *
 * ***************** */
export interface SectionTitleProps {
  externalRef?: any;
  theme?: Theme;
  testId?: string;
  heading: string | Localisation;
  subHeading?: string | Localisation;
  main?: boolean;
  size?: SectionTitleSizes;
  className?: string;
  id?: string;
}

export enum SectionTitleSizes {
  LARGE = 'LARGE',
  REGULAR = 'REGULAR'
}

/* ***************** *
 *       Styles      *
 * ***************** */
const Container = styled.div(({ theme }: ThemeProps) => ({
  marginBottom: theme.custom.spacing.large
}));

interface HeaderProps extends ThemeProps {
  size: SectionTitleSizes;
}

const H2: any = styled.h2(({ theme, size }: HeaderProps) => ({
  ...(theme.custom.typography[size === SectionTitleSizes.LARGE ? 'h2' : 'h3'] as any),
  width: '100%',
  margin: 0,
  fontSize: '3rem'
}));

const H1: any = styled.h1(({ theme, size }: HeaderProps) => ({
  ...(theme.custom.typography[size === SectionTitleSizes.LARGE ? 'h2' : 'h3'] as any),
  width: '100%',
  margin: 0,
  fontSize: '3rem'
}));

const SectionTitleSubHeading = styled.p(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.paragraph as any),
  margin: 0,
  marginTop: theme.custom.spacing.xSmall
}));

/* *************************** *
 *    SectionTitleComponent    *
 * *************************** */

export const SectionTitleComponent = (props: SectionTitleProps) => {
  const t: any = useI18NextContext();
  const { heading, subHeading, size = SectionTitleSizes.REGULAR, className, id, externalRef, main } = props;

  /* *** LOGIC *** */
  const getHeadingText = (text: string | Localisation) => {
    if (typeof text === 'string') {
      return t(text);
    }

    return t(text.label, text.vars);
  };
  const HeadingComponent = main ? H1 : H2;
  /* *** RENDERED *** */
  return (
    <Container data-testid={props.testId || TestId.sectionTitle.main} className={className} id={id} ref={externalRef}>
      <HeadingComponent data-testid={TestId.sectionTitle.heading} size={size}>
        {getHeadingText(heading)}
      </HeadingComponent>
      {!!subHeading && (
        <SectionTitleSubHeading data-testid={TestId.sectionTitle.subHeading}>
          {getHeadingText(subHeading)}
        </SectionTitleSubHeading>
      )}
    </Container>
  );
};

export const SectionTitle = withTheme(SectionTitleComponent);
