import styled from '@emotion/styled';
import { withTheme } from '@emotion/react';
import React, { useState } from 'react';
import { TestId } from '@components/test-ids';
import { Theme, ThemeProps } from '@theme/base';
import { useI18NextContext } from '@components/hooks';
import { BulletPointList, ListItem } from '@components/common/list/bullet-point/BulletPointList';
import { SkeletonElement } from '@components/common/skeleton-loading';
import { ReadMore } from '@styles/common';

/* ***************** *
 *       Types       *
 * ***************** */
export interface ProductFacilitiesProps {
  theme?: Theme;
  testId?: string;
  items: Array<string>;
  errata?: Array<string>;
  initialNoOfItems?: number;
  title: string;
  isGhosting?: boolean;
}

/* ***************** *
 *       Styles      *
 * ***************** */
const Container = styled.div({});

const ViewAllFacilities = styled(ReadMore)(({ theme }: ThemeProps) => ({
  display: 'inline-block',
  marginTop: theme.custom.spacing.large,
  textDecoration: 'underline'
}));

const ErrataContainer = styled.ul(({ theme }: ThemeProps) => ({
  display: 'block',
  borderRadius: 3,
  width: '100%',
  padding: theme.custom.spacing.medium,
  border: `1px solid ${theme.custom.colors.group10.light}`,
  backgroundColor: theme.custom.colors.group10.lighter,
  listStyle: 'none',
  marginTop: theme.custom.spacing.xLarge
}));

const ErrataItem = styled.li(({ theme }: ThemeProps) => ({
  ...(theme.custom.typography.paragraph as any)
}));

/* ******************************** *
 *    ProductFacilitiesComponent    *
 * ******************************** */

export const ExpandableBulletPointListComponent = ({
  testId,
  items,
  initialNoOfItems,
  errata = [],
  title,
  isGhosting
}: ProductFacilitiesProps) => {
  const t: any = useI18NextContext();

  if (isGhosting) {
    return (
      <Container>
        <BulletPointList
          title={title}
          items={[...Array(8)].map((_, index) => `skeletonListItem-${index}`)}
          renderItem={(item: string) => (
            <ListItem data-testid={TestId.list.listItem} key={item}>
              <SkeletonElement width={'80%'} />
            </ListItem>
          )}
        />
      </Container>
    );
  }

  if (!items.length || !items[0]) {
    return null;
  }

  const numOfFacilitiesToRender = initialNoOfItems!;

  /* *** STATE *** */
  const [renderAllFacilities, setRenderAllFacilities] = useState<boolean>(false);

  const renderErratas = () =>
    errata.map((errataItem: string, index: number) => (
      <ErrataItem data-testid={TestId.productFacilities.errataItem} key={`${errataItem}-${index}`}>
        {errataItem}
      </ErrataItem>
    ));
  const showViewAll: boolean = !renderAllFacilities && items.length > numOfFacilitiesToRender;
  return (
    <Container data-testid={testId || TestId.productFacilities.main}>
      <BulletPointList title={title} items={items.slice(0, numOfFacilitiesToRender)} />
      {renderAllFacilities && items.length > numOfFacilitiesToRender && (
        <BulletPointList items={items.slice(numOfFacilitiesToRender)} />
      )}
      {showViewAll && (
        <ViewAllFacilities
          data-testid={TestId.productFacilities.viewAllFacilities}
          onClick={() => setRenderAllFacilities(true)}
        >
          {t('common__label--view-all')}
        </ViewAllFacilities>
      )}
      {errata && !!errata.length && !!errata[0] && (
        <ErrataContainer data-testid={TestId.productFacilities.errataContainer}>{renderErratas()}</ErrataContainer>
      )}
    </Container>
  );
};

ExpandableBulletPointListComponent.defaultProps = {
  initialNoOfItems: 10
};

export const ExpandableBulletPointList = withTheme(ExpandableBulletPointListComponent);
