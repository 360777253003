import { useAsync } from 'react-use';
import { ContentApi } from '@model/iceberg/service/content';
import { CarouselResult, CarouselTile as CarouselTileModel } from '@model/content';
import { TestId } from '@components/test-ids';
import { Carousel } from './Carousel';
import { CarouselTile } from './CarouselTile';

interface DynamicCarouselProps {
  title?: string;
  carouselId: string | number;
  testId?: string;
  renderTiles?: (tiles: Array<CarouselTileModel>) => Array<JSX.Element>;
}

export const DynamicCarousel = ({ title, testId, carouselId, renderTiles }: DynamicCarouselProps) => {
  const {
    value,
    loading
  }: {
    loading: boolean;
    value?: CarouselResult;
  } = useAsync(async () => await new ContentApi().getCarousel(carouselId));

  if (loading) {
    return null; // skeleton
  }

  if (!value) {
    return null;
  }
  const { tiles } = value;

  const renderAllTiles = renderTiles
    ? renderTiles
    : (tiles: Array<CarouselTileModel>) => {
        return (tiles || []).map((tile) => <CarouselTile key={tile.heading} {...tile} />);
      };

  return (
    <Carousel testId={testId || TestId.dynamicCarousel.main} title={title}>
      {renderAllTiles(tiles)}
    </Carousel>
  );
};
