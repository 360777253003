import { mq } from '@styles/breakpoints';
import styled from '@emotion/styled';
import React, { Component } from 'react';
import { TestId } from '@components/test-ids';
import { SectionTitle } from '@components/common/section-title';
import { ThemeProps } from '@theme/base';

export interface BaseListProps<T> {
  title?: string;
  items: Array<T>;
  renderItem?: (item: T) => React.ReactNode;
}

const Container = styled.ul({
  display: 'block',
  padding: 0,
  margin: 0,
  [mq.small]: {
    columnCount: 2
  }
});

const ListItem = styled.li(({ theme }: ThemeProps) => ({
  listStyle: 'none',
  ...(theme.custom.typography.paragraph as any),
  padding: theme.custom.spacing.xSmall
}));

export class BaseList<T> extends Component<BaseListProps<T>> {
  render() {
    const { items, renderItem, title } = this.props;
    const renderListItems = () =>
      items.map((item: T, index: number) =>
        renderItem ? (
          renderItem(item)
        ) : (
          <ListItem key={index} data-testid={TestId.list.listItem}>
            {item}
          </ListItem>
        )
      );
    return (
      <>
        {title && <SectionTitle heading={title} testId={TestId.list.title} />}
        <Container data-testid={TestId.list.base}>{renderListItems()}</Container>
      </>
    );
  }
}
