import React from 'react';
import Slider, { Settings } from 'react-slick';
import Link from 'next/link';
import styled from '@emotion/styled';
import { withTheme } from '@emotion/react';
import { mq } from '@styles/breakpoints';
import { Theme, ThemeProps } from '@theme/base';
import { Icons } from '@model/common/icons';
import { LinkItem } from '@model/iceberg';
import { TestId } from '@components/test-ids';
import { SectionTitle, SectionTitleSizes } from '@components/common/section-title';
import { useResize } from '@components/hooks';
import { CtaButton, ButtonSize, ButtonVariant, ButtonColor } from '@components/material-ui';
import { Icon } from '@components/common/generic-icon';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

/* ***************** *
 *     Constants     *
 * ***************** */

export const MAX_SLIDES_TO_SHOW = 4;

const SliderDefaults = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  swipeToSlide: true
  // afterChange: function (index) {
  //   console.log(`Slider Changed to: ${index + 1}`);
  // }
};

/* ***************** *
 *       Types       *
 * ***************** */

type CarouselLink = {
  label: string;
  onClick?: () => void;
  href?: string;
  linkItem?: LinkItem;
};

interface CarouselProps extends Settings {
  children: Array<JSX.Element>;
  className?: string;
  testId?: string;
  title?: string;
  subTitle?: string;
  link?: CarouselLink;
  alignLeft?: boolean;
  theme: Theme;
}

/* ***************** *
 *       Styles      *
 * ***************** */

interface SliderProps extends ThemeProps {
  centerMode: boolean;
  alignLeft?: boolean;
}

const Container: any = styled.div(({ theme }: SliderProps) => ({
  marginLeft: -theme.custom.spacing.large,
  marginRight: -theme.custom.spacing.large,
  overflowX: 'hidden',
  [mq.small]: {
    marginLeft: 0,
    marginRight: 0,
    overflowX: 'visible'
  }
}));

const TopBar: any = styled.div(({ theme }: SliderProps) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: theme.custom.spacing.large,
  marginLeft: theme.custom.spacing.large,
  marginRight: theme.custom.spacing.large,
  [mq.small]: {
    marginLeft: 0
  }
}));

const StyledTitle = styled(SectionTitle)({
  marginBottom: 0
});

const CarouselLink = styled(Link)(({ theme }: ThemeProps) => ({
  boxSizing: 'border-box',
  color: theme.custom.colors.group4.base,
  display: 'inline-block',
  fontSize: '2rem',
  paddingLeft: theme.custom.spacing.large,
  paddingRight: theme.custom.spacing.large,
  width: '100%',
  ['&:hover,&:active,&:visited']: {
    color: theme.custom.colors.group4.base
  },
  [mq.small]: {
    paddingLeft: 0,
    paddingRight: 0
  }
}));

const ArrowIcon: any = styled(Icon)(({ theme }: ThemeProps) => ({
  color: theme.custom.colors.group2.base
}));

interface ArrowButtonProps extends ThemeProps {
  next: boolean;
  isDisabled: boolean;
  isHidden: boolean;
  inset?: boolean;
}

const ArrowButton: any = styled(CtaButton)(({ theme, next, inset, isDisabled, isHidden }: ArrowButtonProps) => ({
  marginTop: 0,
  opacity: isDisabled ? 0 : 1,
  position: 'absolute',
  left: next ? 'unset' : inset ? theme.custom.spacing.large : -theme.custom.spacing.large,
  right: next ? (inset ? theme.custom.spacing.large : -theme.custom.spacing.large) : 'unset',
  top: '50%',
  transform: 'translateY(-50%)',
  zIndex: 1,
  ['.MuiButtonBase-root']: {
    padding: 0,
    minWidth: 50,
    width: 50,
    height: 50,
    backgroundColor: theme.custom.colors.white,
    border: `2px solid ${theme.custom.colors.group2.base}`,
    borderRadius: '50%',
    boxShadow: `rgba(0, 0, 0, 0.3) 0px 8px 12px`,
    transition: 'box-shadow 0.3s ease',
    ['&:hover']: {
      boxShadow: 'rgba(0, 0, 0, 0.6) 0px 2px 4px'
    },
    ['.MuiTouchRipple-root']: {
      borderRadius: '50%'
    }
  },
  ['.MuiButton-endIcon']: {
    margin: 0
  },
  display: 'none',
  [mq.small]: {
    display: 'inline-block',
    ...(isHidden && {
      display: 'none'
    })
  }
}));

interface ArrowProps extends ArrowButtonProps {
  className: string;
  onClick: () => void;
}
export const Arrow: any = (props: ArrowProps) => {
  const { onClick, theme, next, className, inset, isHidden } = props;
  const isDisabled = /slick-disabled/.test(className);

  return (
    <ArrowButton
      onClick={onClick}
      color={ButtonColor.SECONDARY}
      variant={ButtonVariant.OUTLINED}
      size={ButtonSize.SMALL}
      label={''}
      next={next}
      inset={inset}
      isDisabled={isDisabled}
      isHidden={isHidden}
      endIcon={<ArrowIcon theme={theme} name={next ? Icons.ARROW_RIGHT : Icons.ARROW_LEFT} />}
    />
  );
};

const StyledSlider: any = styled(Slider)(({ theme, alignLeft }: SliderProps) => ({
  ['.slick-list']: {
    paddingBottom: 6,
    overflowX: 'scroll',
    ['::-webkit-scrollbar']: { display: 'none' },
    [mq.small]: {
      overflowX: 'visible'
    }
  },
  ['.slick-track']: {
    display: 'flex !important',
    marginLeft: 0,
    marginRight: 0,
    ...(alignLeft && { width: '100% !important', transform: 'none !important' }),
    [mq.small]: {
      // marginLeft: -theme.custom.spacing.medium
    }
  },
  ['.slick-slide']: {
    height: 'inherit',
    paddingLeft: theme.custom.spacing.large,
    paddingRight: 0,
    [mq.small]: {
      paddingRight: 6
    },
    ['&:first-of-type']: {
      marginLeft: theme.custom.spacing.large,
      paddingLeft: 1,
      [mq.small]: {
        marginLeft: 0
      }
    },
    ['&:last-of-type']: {
      marginRight: theme.custom.spacing.large,
      [mq.small]: {
        marginRight: 0
      }
    },
    ['> div']: {
      height: '100%'
    },
    ['div[role=link]']: {
      borderRadius: `10px !important`
    }
  }
}));

/* ******************** *
 *       Carousel       *
 * ******************** */

export const Carousel = withTheme(
  ({
    children,
    slidesToShow,
    slidesToScroll,
    title,
    subTitle,
    link,
    className,
    testId,
    alignLeft,
    theme
  }: CarouselProps) => {
    if (!children) return null;

    const [isDesktop, isMobile, isTablet, isSmallDesktop] = useResize();
    const childCount = children.length;

    const maxSlidesToShow = isMobile ? 1 : isTablet ? 2 : isSmallDesktop ? 3 : MAX_SLIDES_TO_SHOW;
    const slideCount = Math.min(slidesToShow || childCount, maxSlidesToShow);
    const scrollSlides = isDesktop ? slidesToScroll || 1 : slideCount;
    const hideArrows = childCount <= slideCount;

    const settings = {
      ...SliderDefaults,
      swipe: !isMobile && childCount > slideCount,
      nextArrow: <Arrow theme={theme} next isHidden={hideArrows} />,
      prevArrow: <Arrow theme={theme} isHidden={hideArrows} />,
      slidesToShow: slideCount,
      slidesToScroll: scrollSlides,
      infinite: false
    };

    const renderLink = ({ label, linkItem, href, onClick }: CarouselLink) => {
      if (!href && !linkItem) {
        return null;
      }

      return (
        <CarouselLink href={''} {...(href && { href })} {...(linkItem && { ...linkItem })} passHref>
          <CtaButton
            onClick={onClick}
            label={label}
            color={ButtonColor.SECONDARY}
            variant={ButtonVariant.OUTLINED}
            size={ButtonSize.REGULAR}
            testId={TestId.carousel.cta}
          />
        </CarouselLink>
      );
    };

    return (
      <Container className={className} data-testid={testId || TestId.carousel.container}>
        {title && (
          <TopBar>
            <StyledTitle heading={title} subHeading={subTitle} size={SectionTitleSizes.LARGE} />
          </TopBar>
        )}
        <StyledSlider
          {...settings}
          alignLeft={!isMobile && alignLeft && childCount < maxSlidesToShow}
          centerMode={isMobile}
          centerPadding={isMobile && childCount > 1 ? 24 : 0}
        >
          {children}
        </StyledSlider>
        {link && renderLink(link)}
      </Container>
    );
  }
);
