import React, { useState, useRef, CSSProperties as ReactCSSProperties } from 'react';
import styled from '@emotion/styled';
import { Grid, AppBar, Tab } from '@mui/material';
import { CSSProperties } from '@mui/styles';
import { SectionSeparator } from '@styles/layout';
import { mq } from '@styles/breakpoints';
import { ZIndex } from '@styles/z-index';
import { Tabs } from '@styles/material-ui';
import { boxShadowMixins } from '@styles/mixins';
import { useTheme, withTheme } from '@emotion/react';
import SwipeableViews from 'react-swipeable-views';
import { TestId } from '@components/test-ids';
import { Theme, ThemeProps } from '@theme/base';
import { Icon } from '@components/common/generic-icon';
import { Icons } from '@model/common/icons';
import { Button, ButtonVariant } from '@components/material-ui';
import { ConditionalWrapper } from '@util/common';

/* ***************** *
 *       Types       *
 * ***************** */
export interface ContentTabsProps {
  theme?: Theme;
  testId?: string;
  children: Array<any>;
  tabLabels: Array<string>;
  tabIcons?: Array<string>;
  barLabels?: Array<string>;
  styles?: CSSProperties;
  className?: string;
  initialTabIndex?: number;
  canScroll?: boolean;
  isGrid?: boolean;
  toggleLabels?: Array<string>;
  showToggleIcons?: boolean;
  swipeableStyles?: CSSProperties;
}

/* ***************** *
 *       Styles      *
 * ***************** */
interface ContainerProps extends ThemeProps {
  styles?: ReactCSSProperties;
}

const Container: any = styled.div(({ theme, styles }: ContainerProps) => ({
  ['.MuiAppBar-root']: {
    backgroundColor: theme.custom.colors.group10.lighter,
    boxShadow: boxShadowMixins(theme.custom.colors.group10.base).default,
    border: `1px solid ${theme.custom.colors.group10.light}`,
    marginBottom: theme.custom.spacing.xLarge,
    zIndex: ZIndex.CONTENT
  },

  ['.MuiTab-root']: {
    transition: `color ${theme.custom.transitions.default}`,
    ...(theme.custom.typography.paragraph as any),
    color: theme.custom.colors.group4.base,
    textTransform: 'none',

    ['&:hover']: {
      ['&:after']: {
        backgroundColor: theme.custom.colors.group10.base,
        borderRadius: 3,
        bottom: 0,
        content: '""',
        height: 5,
        left: 0,
        position: 'absolute',
        width: '100%'
      }
    }
  },

  ['.Mui-selected']: {
    ['button, i']: {
      color: theme.custom.colors.group1.base,

      ['&:hover']: {
        color: theme.custom.colors.group1.base,
        ['i']: {
          color: theme.custom.colors.group1.base
        }
      }
    }
  },

  ['.MuiTabs-indicator']: {
    backgroundColor: theme.custom.colors.group1.base,
    height: 5,
    borderRadius: 3
  },

  ['button.MuiTab-root']: {
    ['@media (max-width: 650px)']: {
      width: '40%'
    }
  },

  ...styles
}));

const GuestsDetails: any = styled.span(({ theme }: ThemeProps) => ({
  display: 'none',

  [mq.large]: {
    display: 'inherit',
    ...(theme.custom.typography.paragraph as any),
    color: theme.custom.colors.group4.light,
    padding: `0 ${theme.custom.spacing.medium}px`
  }
}));

const TabContentWrapper: any = styled.div(({ isHidden }: any) => ({
  display: isHidden ? 'none' : 'block',
  overflow: 'hidden'
}));

const ArrowIcon: any = styled(Icon)(({ theme }: ThemeProps) => ({
  fontSize: '1.6rem',
  color: theme.custom.colors.group2.base
}));

/* *************************** *
 *    ContentTabsComponent    *
 * *************************** */

export const ContentTabsComponent = ({
  children,
  barLabels,
  tabLabels,
  styles,
  tabIcons,
  className,
  initialTabIndex = 0,
  canScroll,
  isGrid,
  toggleLabels,
  testId,
  showToggleIcons,
  swipeableStyles
}: ContentTabsProps) => {
  const [tabIndex, setTabIndex] = useState<number>(initialTabIndex);
  const theme: Theme = useTheme();
  const containerRef = useRef<HTMLDivElement>(null);

  /* *** LOGIC *** */
  function handleChange(event: any, newValue: number) {
    event.preventDefault();
    setTabIndex(newValue);
  }

  function handleChangeIndex(index: number) {
    setTabIndex(index);
    containerRef.current?.scrollIntoView(true);
  }

  /* *** RENDERERS *** */
  const renderTabs = () =>
    tabLabels.map((label: string, index: number) => (
      <Tab
        key={`${label}-${index}`}
        {...(!!tabIcons && tabIcons[index] && { icon: <Icon name={tabIcons[index]} /> })}
        label={label}
      />
    ));

  const nextTabIndex = (tabIndex + 1) % children.length;

  return (
    <ConditionalWrapper
      condition={!!toggleLabels?.length && children.length > 1}
      wrapper={(children) => <div ref={containerRef}>{children}</div>}
    >
      <Container data-testid={testId || TestId.contentTabs.main} className={className} styles={styles}>
        {children.length > 1 ? (
          <>
            <AppBar position="static" color="default">
              <Tabs value={tabIndex} onChange={handleChange} variant={canScroll ? 'scrollable' : 'fullWidth'}>
                {renderTabs()}
              </Tabs>
              {barLabels && !!barLabels.length && <GuestsDetails>{barLabels[tabIndex]}</GuestsDetails>}
            </AppBar>
            <SwipeableViews axis={'x'} index={tabIndex} onChangeIndex={handleChangeIndex} style={swipeableStyles}>
              {children.map((child: any, index: number) => (
                <TabContentWrapper
                  key={index}
                  className={`tabsContentWrapper${index !== tabIndex ? ' hidden' : ''}`}
                  isHidden={tabIndex !== index}
                >
                  {isGrid ? (
                    <Grid container spacing={theme.custom.gridSpacing.default}>
                      {child}
                    </Grid>
                  ) : (
                    child
                  )}
                </TabContentWrapper>
              ))}
            </SwipeableViews>
            {!!toggleLabels?.length && (
              <>
                <SectionSeparator size={theme.custom.spacing.medium} />
                <Button
                  testId={TestId.contentTabs.button}
                  variant={ButtonVariant.OUTLINED}
                  onClick={() => handleChangeIndex(nextTabIndex)}
                  label={toggleLabels[nextTabIndex]}
                  endIcon={showToggleIcons && nextTabIndex > 0 ? <ArrowIcon name={Icons.ARROW_RIGHT} /> : undefined}
                  startIcon={showToggleIcons && nextTabIndex === 0 ? <ArrowIcon name={Icons.ARROW_LEFT} /> : undefined}
                />
              </>
            )}
          </>
        ) : (
          children
        )}
      </Container>
    </ConditionalWrapper>
  );
};

export const ContentTabs = withTheme(ContentTabsComponent);
